<template>
  <section id="portfolio" class="portfolio">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Portfolio</h2>
        <p>A presentation of recent projects I have been working on.</p>
      </div>

      <h3>Open-sourced projects</h3>
      <p>
        I am the main author of several open-source projects mostly revolving
        around providing a smoother workflow with Python, enabling CI/CD and
        promoting good practice.
      </p>
      <div
        class="row project-content justify-content-center"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="col-lg-1">
          <img src="../assets/img/portfolio/python-image.svg" />
        </div>
        <div class="col-lg-8">
          <h4>
            Python Docker images
            <a
              href="https://github.com/arnaudblois/python-ubuntu-image/"
              target="_blank"
              alt="Github for Python Ubuntu images"
              ><i class="bi bi-github"></i
            ></a>
          </h4>
          <p>
            This project generates several curated Docker images providing the
            latest Python on Ubuntu. Unlike the other mainstream images, the
            linking is done against the latest openSSL for security. The build
            is also extremely optimised for performance and lightweightness.
          </p>
        </div>
      </div>

      <div
        class="row project-content justify-content-center"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="col-lg-1">
          <img src="../assets/img/portfolio/pytemplator.svg" />
        </div>
        <div class="col-lg-8">
          <h4>
            PyTemplator package
            <a
              href="https://github.com/arnaudblois/pytemplator/"
              target="_blank"
              alt="Github for PyTemplator"
              ><i class="bi bi-github"></i
            ></a>
          </h4>
          <p>
            A rewritten enhanced alternative to the
            <it>cookiecutter</it> package. The PyTemplator allows the automatic
            creation of a customised boilerplate from any compatible template.
            The customisation is done via an interactive prompt providing more
            flexibility than <it>cookiecutter</it>.
          </p>
        </div>
      </div>

      <div
        class="row project-content justify-content-center"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="col-lg-1">
          <img
            src="../assets/img/portfolio/pypi-defender.svg"
            class="img-fluid"
          />
        </div>
        <div class="col-lg-8">
          <h4>Pypi Defender</h4>
          <p>
            A Python package designed to perform sanity-checks and gate-keeping
            on the packages produced internally by an organisation. Handles
            internal structure, naming conventions and namespaces, etc
          </p>
        </div>
      </div>

      <div
        class="row project-content justify-content-center"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="col-lg-1">
          <img src="../assets/img/portfolio/misc.png" class="img-fluid" />
        </div>
        <div class="col-lg-8">
          <h4>
            Various examples and code challenges.
            <a
              href="https://github.com/arnaudblois/"
              target="_blank"
              alt="General Github"
              ><i class="bi bi-github"></i
            ></a>
          </h4>
          <p>
            Feel free to explore my GitHub for more examples and boilerplates
            for various aspects of Python (packaging, API) as well as my
            solutions to different code challenges (LeetCode, CtCI, etc)
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Services",
};
</script>

<style lang="scss">
@import "@/design";
.portfolio {
  padding-top: $content-offset;
  padding-bottom: 100px;
  .project-content {
    padding-bottom: 1rem;
    img {
      max-width: 100%;
      padding: 10px;
    }
    h4 a {
      display: inline-block;
      float: right;
    }
  }
  .project-content:hover {
    filter: grayscale(0%);
    img {
      box-shadow: 0 0 15px #0ff;
    }
  }
  @media (max-width: 992px) {
    .project-content img {
      max-width: 20%;
    }
  }
}
</style>

<template>
  <div
    id="testimonial-carousel"
    class="testimonial-carousel carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          src="../assets/img/testimonials/chocoli.jpeg"
          class="d-block"
          alt="Marion Sourribes"
        />
        <h3>Marion Sourribes</h3>
        <h4>Vice-President at Morgan Stanley</h4>
        <p>
          <i class="quote-icon-left">“</i>
          I have known Arnaud for many years and had the occasion to work with
          him on a variety of projects from cryptocurrency to complex data
          analysis. He always impresses me with the depth of his tech expertise,
          but also with his kind and energic personality.
          <i class="quote-icon-right">”</i>
        </p>
      </div>
      <div class="carousel-item">
        <img
          src="../assets/img/testimonials/mariusli.jpeg"
          class="d-block"
          alt="Marius Bauza"
        />
        <h3>Marius Bauza</h3>
        <h4>CTO &amp; co-founder</h4>
        <p>
          <i class="quote-icon-left">“</i>
          Arnaud's expertise proved instrumental in our efforts to scale up our
          operations. He went the extra mile to ensure our success.
          <i class="quote-icon-right">”</i>
        </p>
      </div>
      <div class="carousel-item">
        <img
          src="../assets/img/testimonials/shadow.jpg"
          class="d-block"
          alt="Shadow"
        />
        <h3>Shadow</h3>
        <h4>
          Husky &amp; Influencer
          <a
            href="https://instagram.com/shadowthesuperhusky"
            class="instagram"
            target="_blank"
          >
            <i class="bi bi-instagram"></i>
          </a>
        </h4>
        <p>
          <i class="quote-icon-left">“</i>
          (translated) My hooman is the best, I really enjoy peer-programming
          with him. He does the typing I do the chewing.
          <i class="quote-icon-right">”</i>
        </p>
      </div>
    </div>
    <div class="carousel-indicators swiper-pagination">
      <button
        type="button"
        data-bs-target="#testimonial-carousel"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#testimonial-carousel"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#testimonial-carousel"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#testimonial-carousel"
      data-bs-slide="prev"
    >
      <span
        class="carousel-control-prev-icon controls"
        aria-hidden="true"
      ></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <div class="control-next">
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#testimonial-carousel"
        data-bs-slide="next"
      >
        <span
          class="carousel-control-next-icon controls"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialsCarousel",
};
</script>

<style lang="scss">
@import "@/design";

#testimonial-carousel {
  .controls {
    background-color: $resume-sideline-color;
  }

  .carousel-indicators {
    button {
      border-radius: 50%;
      height: 20px;
      width: 20px;
      background-color: $resume-sideline-color;
      color: $resume-sideline-color;
    }
  }
  .swiper-pagination {
    //margin-top: 20px;
    position: relative;
    margin-bottom: 100px;
  }

  .section-header {
    margin-bottom: 40px;
  }

  .carousel {
    overflow: hidden;
  }
  .carousel-item {
    text-align: center;
    img {
      border-radius: 50%;
      border: 4px solid #fff;
      margin: 0 auto;
      width: 30%;
    }
    h3 {
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0 5px 0;
      color: #111;
    }
    h4 {
      font-size: 14px;
      color: #999;
      margin: 0 0 15px 0;
    }
    .quote-icon-left,
    .quote-icon-right {
      color: #c8efea;
      font-size: 50px;
    }
    .quote-icon-left {
      display: inline-block;
      left: -5px;
      position: relative;
    }
    .quote-icon-right {
      display: inline-block;
      right: -5px;
      position: relative;
      top: 10px;
    }
    p {
      font-style: italic;
      position: relative;
      margin: 0 auto 15px auto;
    }
  }
}

@media (min-width: 992px) {
  #testimonial-carousel .testimonial-item p {
    width: 80%;
  }
}
</style>

<template>
  <section id="skills" class="skills">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Skills</h2>
        <p>
          An overview of the main technologies I have used as well as the
          different contexts on which I've leveraged them.
        </p>
      </div>

      <div
        class="row skills-content justify-content-center"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="col-lg-1">
          <img src="../assets/img/skills/Python-logo-notext.svg" />
        </div>
        <div class="col-lg-8">
          <h4>Python</h4>
          <p>
            I have used Python extensively in a variety of context: from
            performing data collection and processing in scientific research, to
            driving CI/CD and audit processes, as well as designing and
            deploying complex API for various industries. I often have had a
            centralising role in my past positions which gave me a good exposure
            to use cases and challenges faced by heavy Python users such as data
            scientists.
          </p>
        </div>
      </div>

      <div
        class="row skills-content justify-content-center"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="col-lg-1">
          <img src="../assets/img/skills/dj.png" />
        </div>
        <div class="col-lg-8">
          <h4>Django</h4>
          <p>
            I have a particular expertise in Django and Django REST framework,
            having it used extensively to create both internal and
            customer-facing API and web servers. Its powerful ORM as well as
            excellent performance and maintenability makes it a perfect choice
            for the task.
          </p>
        </div>
      </div>

      <div
        class="row skills-content justify-content-center"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="col-lg-1">
          <img src="../assets/img/skills/vue.svg" class="img-fluid" />
        </div>
        <div class="col-lg-8">
          <h4>Vue 3</h4>
          <p>
            As a fullstack dev I often had to dabble with JavaScript and
            frameworks such as jQuery, Angular.js, etc which I considered as
            necessary evil. React was already much more satisfying but Vue won
            the place in my heart of my preferred front-end ramework. Light,
            extremely efficient with a great documentation and Community, I
            enjoyed using it a lot. This is powering the present website.
          </p>
        </div>
      </div>

      <div
        class="row skills-content justify-content-center"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="col-lg-1">
          <img src="../assets/img/skills/k8s.png" class="img-fluid" />
        </div>
        <div class="col-lg-8">
          <h4>Docker and Kubernetes</h4>
          <p>
            Containerization has been a ground-breaking tech for CI/CD, ensuring
            reproducibility across builds and systems. I have been spearheading
            this effort for Python at GS, and I am also currently maintaining
            open-source Python images focussed on performance and security.
          </p>
        </div>
      </div>

      <div
        class="row skills-content justify-content-center"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="col-lg-1">
          <img
            src="../assets/img/skills/world_wide_web.svg"
            class="img-fluid"
          />
        </div>
        <div class="col-lg-8">
          <h4>Cloud and web tech</h4>
          <p>
            I have experience with the main providers (AWS, GCP, Azure), as well
            as working with dedicated servers. Most of my career was with
            financial institutions and I've received thourough training
            regarding security and safe data handling and persistence (ISO
            27001)
          </p>
        </div>
      </div>

      <div
        class="row skills-content justify-content-center"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="col-lg-1">
          <img src="../assets/img/skills/gold-star.jpeg" class="img-fluid" />
        </div>
        <div class="col-lg-8">
          <h4>Other non-technical skills</h4>
          <p>
            Being a senior developer involves much more than just technology -
            this might not even be the most important
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Skills",
};
</script>

<style lang="scss">
@import "@/design";

.skills-content {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  padding-bottom: 1rem;
  img {
    max-width: 100%;
    padding: 10px;
  }
}
.skills-content:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
@media (max-width: 992px) {
  .skills-content img {
    max-width: 35%;
  }
}
</style>
